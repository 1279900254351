<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col>
        <template>
          <v-card elevation="0">
            <v-data-table
              :headers="TransitModelHeader"
              :items="allchalanArray"
              :search="searchchalan"
              :loading="loading"
              loading-text="Please wait..."
              item-key="id"
              v-model="selected"
              :single-select="singleSelect"
              :server-items-length="totalItems1"
              hide-default-footer
              show-select
              class="elevation-1"
              style="background: #f5f5f5"
              return-object
            >
              <template v-slot:top>
                <v-toolbar
                  elevation="2"
                  style="background: #f5f5f5; font-size: 26px"
                >
                  <v-toolbar-title
                    style="margin-left: -14px"
                    class="mt-2 mb-2 ml-2"
                  >
                    <v-select
                      v-model="trnasferob"
                      :items="AllWarehouseList"
                      item-text="name"
                      @change="TransferSelectInfo(trnasferob)"
                      return-object
                    ></v-select>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- <div class="pa-5">
                    <v-text-field
                      v-model="searchchalan"
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                    ></v-text-field>
                  </div> -->
                  <div class="pa-5">
                    <v-btn
                      color="primary"
                      @click="savetransit(item)"
                      :disabled="!selected.length"
                    >
                      Create Chalan
                    </v-btn>
                  </div>
                  <div class="pr-4 pb-2 mt-5 pt-2 d-flex ">
                    <div class="search g">
                        <v-text-field v-model="search_key" label="Search by product id" filled rounded   dense hide-details>
                        </v-text-field>
                    </div>
                    <div class="icon-search pr-4 pb-5 pt-2">
                        <v-btn icon style="background: red"><v-icon      @click="searchHandle">mdi-magnify</v-icon>
                        </v-btn>                                                          
                    </div>
                    
                  </div>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
              </template>
              <template
                v-slot:item.actionsqty="{ item }"
                style="max-width: 30px"
              >
                <v-text-field
                  v-model="item.requested_qty"
                  outlined
                  dense
                  class="mt-5"
                  style="max-width: 100px"
                ></v-text-field>
              </template>

              <template v-slot:item.admin_status="{ item }">
                <v-chip :color="getColor(item.admin_status)" dark>
                  {{ item.admin_status }}
                </v-chip>
              </template>
            </v-data-table>

            <v-dialog v-model="Transfersavedialog" max-width="900px">
              <v-card>
                <v-data-table
                  :headers="TransitModelHeader"
                  :items="selected"
                  item-key="id"
                  v-model="selected"
                  :single-select="singleSelect"
                  show-select
                  class="elevation-1"
                  style="background: #f5f5f5"
                  return-object
                  hide-default-footer
                  :server-items-length="totalItems1"
                  disable-pagination
                >
                  <template v-slot:top>
                    <v-toolbar
                      elevation="2"
                      style="background: #f5f5f5; font-size: 26px"
                    >
                      <p class="mb-0">Confirm Product</p>
                      <v-spacer></v-spacer>

                      <v-divider class="mx-4" inset vertical></v-divider>
                    </v-toolbar>
                  </template>
                </v-data-table>
                <v-card-actions class="py-5">
                  <v-spacer></v-spacer>

                  <v-btn elevation="0" color="red" @click="CancelInvoice()">
                    Cancel
                  </v-btn>
                  <v-btn
                    v-if="isConfirmChalanButtonClick"
                    @click="SaveApproveInvoice(item)"
                    elevation="0"
                    color="success"
                  >
                    Confirm Chalan
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <div class="d-flex justify-content-center mt-5">
              <!-- <v-col cols="3" md="3"> -->
              <div class="col-4">
                  Total {{ totalItems1 }} records
              </div>
              <!-- </v-col> -->
              <!-- <v-col cols="3" md="3"> -->
              <div class="col-4">
                  <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
                  </v-select>
                  <!-- <span>Show</span>
                   @change="handlePageSizeChange($event)" -->
                  <!-- <select v-model="perPage" @change="handlePageSizeChange($event)">
                      <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
                  </select> --> 

              </div>
              <!-- </v-col> -->
              <!-- <v-col cols="6" md="3" > -->
              <div class="col-4">
                  <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
                  </v-pagination>
              </div>

              <!-- </v-col> -->
          </div>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    crap: false,
    previews: {},
    SpecificationArray: [],
    searchspec: "",
    perPage1: '10',
    currentPage1: 1,
    totalItems1: 0,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100', 'All'],
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "image/png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: true,
      autoCrop: true,
      autoCropWidth: 475,
      autoCropHeight: 475,
      centerBox: false,
      high: true,
      max: 99999,
    },
    show: true,
    fixed: true,
    fixedNumber: [1, 1],

    menu: false,
    menu2: false,
    search: "",
    adddialog: false,
    editdialog: false,
    valid: false,
    snackbar: false,
    text: "",
    color: "",
    check: true,
    isConfirmChalanButtonClick: true,
    validForm: false,
    editProductForm: false,
    loading: true,
    search_key:null,

    dialog1: false,
    new_category: "",

    dialog2: false,
    new_sub_category: "",

    dialog3: false,
    new_sub_sub_category: "",

    category: [],
    selectedCatyegory: "",

    sub_category: [],
    selectedSubCatyegory: "",

    sub_sub_category: [],
    selectedSubSubCategory: "",

    shares: ["Share with mother site", "Don't share with mother site"],
    select_val: ["kg", "pcs", "gm", "none"],
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Title", value: "title" },
      { text: "Brand", value: "brand", sortable: false },
      { text: "No.Spec", value: "specific_status", sortable: false },
      { text: "", value: "specification", sortable: false },
      { text: "", value: "status", sortable: false },
      // { text: "Selling Price", value: "old_price" },
      { text: "", value: "review", sortable: false },
      { text: "", value: "comments", sortable: false },
      { text: "", value: "link", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    specificationheaders: [
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "id", sortable: false },
      { text: "Title", value: "product_data.title" },
      { text: "Brand", value: "product_data.brand", sortable: false },
      { text: "Color", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      { text: "quantity", value: "quantity", sortable: false },
      { text: "", value: "adquantity", sortable: false },

      // { text: "Selling Price", value: "old_price" },
      // { text: "Purchase Price", value: "review", sortable: false },
      // { text: "Selling Price", value: "comments", sortable: false },
      { text: "Discount", value: "adddiscount", sortable: false },
      { text: "", value: "status", sortable: false },
    ],

    TransitModelHeader: [
      { text: "ID", value: "id", sortable: false },
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "specification_id", sortable: false },
      { text: "Title", value: "product_name" },
      { text: "Brand", value: "brand_name", sortable: false },
      { text: "Color", value: "product_color", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "quantity", value: "requested_qty", sortable: false },
      { text: "Purchase ", value: "purchase_price", sortable: false },
      { text: "Sell", value: "selling_price", sortable: false },
    ],
    ChalanHeader: [
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "id", sortable: false },
      { text: "Title", value: "product_name" },
      { text: "Brand", value: "brand_name", sortable: false },
      { text: "Color", value: "product_color", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "quantity", value: "credit", sortable: false },
      { text: "Purchase ", value: "purchase_price", sortable: false },
      { text: "Selling", value: "selling_price", sortable: false },
    ],
    AllChalanHeader: [
      { text: "Chalan ID", value: "id", sortable: false },
      { text: "Created By", value: "created_by", sortable: false },
      { text: "Approved By", value: "approved_by", sortable: false },
      { text: "Date", value: "date", sortable: false },
      { text: "Status", value: "chalan_status", sortable: false },
      { text: "Name", value: "name" },
      { text: "Location", value: "location", sortable: false },
      { text: "", value: "viewchalan", sortable: false },
    ],
    products: [],
    contact: {},
    editedIndex: -1,
    allBrands: [],

    editedItem: {},

    addItem: {
      radioGroup: false,
      selectedCatyegory: "",
      selectedSubCatyegory: "",
      selectedSubSubCategory: "",
      title: "",
      brand: "",
      description: "",
      key_features: [],
      warranty: "",
      // properties: "",
      origin: "Bangladesh",
      shipping: "Bangladesh",
      description: "",
      image: [],
      publish_st: "Published",
    },

    images: [],
    previewURLS: [],

    user: {},

    title: "",
    brand: "",
    publish_st: "Published",
    description: "",
    key_features: [],
    quantity: "",
    // properties: true,
    point: "",
    price: "",
    purchase_price: "",
    discount_type: "",
    amount: "",
    discount_end_date: "",
    point_end_date: "",
    order: "",
    shipping: "Bangladesh",
    origin: "Bangladesh",
    manufactureCountries: [
      "Bangladesh",
      "India",
      "Pakistan",
      "China",
      "Sri Lanka",
      "Malaysia",
      "Indonesia",
      "Nepal",
      "Bhutan",
      "Maldives",
      "Japan",
      "Philippines",
      "Vietnam",
      "Turkey",
      "Iran",
      "Thailand",
      "Myanmar",
      "South Korea",
      "Saudi Arabia",
      "United Arab Emirates",
      "Singapore",
      "Kuwait",
      "Qatar",
      "Brunei",
      "Bahrain",
    ],
    warranty: "",
    product_unit: "",

    includeFiles: true,
    enabled: false,

    expanded: [],
    singleExpand: false,

    cropBlob: {},
    showCrop: false,
    resultURL: "",
    discount_types: ["none", "amount", "percentage"],
    select: { state: "All Products" },
    items: [
      { state: "All Products" },
      { state: "No Specification" },
      { state: "No Price" },
    ],

    selectspec: { statespec: "All Specifications" },
    itemspec: [
      { statespec: "All Specifications" },
      { statespec: "Shared Specification" },
    ],
    addquantitydialog: false,
    shop: [],
    warehouse: [],
    priceValidationForm: false,
    specdataobj: {
      product_data: {},
      product_price: {
        price: 0,
        purchase_price: 0,
      },
    },
    searchchalan: "",
    AllWarehouseList: [],
    trnasferob: {},
    selected: [],
    Transfersavedialog: false,
    singleSelect: false,
    valid_add_discount: false,
    addDiscountDialog: false,
    specdataobjdiscount: {
      product_data: {},
    },
    product_dist: {
      discount_type: "none",
      discount_amount: 0,
      discount_start_date: "",
      discount_end_date: "",
    },

    menudist: false,
    menu2dist: false,
    addedwarehousequantity: [],
    addedshopquantity: [],
    quantityIndex: -1,
    quantityID: -1,
    allchalanArray: [],
    chalanproducts: [],
    allChalanobj: {},
    viewchalandialog: false,
    tempobj: {
      data: {},
    },
    searchitemArray: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    addquantitydialog: function (newValue) {
      this.addedwarehousequantity.length = 0;
      this.addedshopquantity.length = 0;

      // IF add quantity and price modal is closed then reset all the variable
      if (!newValue) {
        this.quantityIndex = -1;
        this.quantityID = -1;
        this.$refs.priceForm.reset();
      }
    },
  },

  methods: {
    searchHandle(event){
            this.initialize();
        },
    handlePageSizeChange(event) {
        // console.log('page size change method called');
        // console.log('event = ', event);
        this.perPage1 = (event);
        this.loading = true;
        this.TransferSelectInfo();
    },
    handlePageChange(value) {
        // console.log('handle page change value = ', value);
        this.currentPage1 = value;
        this.loading = true;
        this.TransferSelectInfo();
        window.scrollTo(0, 0);
    },
    initialize() {
      axios.get("manager/transferable_list").then((response) => {
        if (response.data.success) {
          this.AllWarehouseList = response.data.data;
          this.AllWarehouseList.forEach((element, index) => {
            element.flagvalue = element.value;
            element.value = index;
          });
        }
      });
    },

    getAllBrands() {
      axios.get("productdetails/get_brands/").then((response) => {
        this.allBrands = response.data.data;
      });
    },

    addFeatures() {
      this.addItem.key_features.push("");
    },

    deleteFeatures(index) {
      this.addItem.key_features.splice(index, 1);
    },

    addFeaturesForEditProduct() {
      this.editedItem.key_features.push("");
    },

    deleteFeaturesForEditProduct(index) {
      this.editedItem.key_features.splice(index, 1);
    },

    DiscountEndInput() {
      this.discount_end_date = this.date;

      this.menu = false;
    },

    PointEndInput() {
      this.point_end_date = this.dates;

      this.menu2 = false;
    },

    deleteImage(index) {
      this.addItem.image.splice(index, 1);
      this.images.splice(index, 1);
    },

    deleteAllImage() {
      this.images.length = 0;
      this.addItem.image.length = 0;
    },

    saveCategory() {
      this.category.push(this.new_category);
      this.dialog1 = false;
    },

    AddSubCategory() {
      this.sub_category.push(this.new_sub_category);
      this.dialog2 = false;
    },
    unpublish() {},

    addSubSubCategory() {
      this.sub_sub_category.push(this.new_sub_sub_category);
      this.dialog3 = false;
    },

    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editdialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios.post(`product/hide_delete/${item.id}/`).then((response) => {
          this.products = this.products.filter(
            (contact) => contact.id != item.id
          );
        });
      }
    },

    getAllCastegory() {
      // console.log("3");
      axios.get("category/categories/").then((response) => {
        this.category = response.data;
      });
    },

    onSelectCategory() {
      axios
        .post(`category/subcategories/`, {
          name: this.addItem.selectedCatyegory,
        })
        .then((response) => {
          this.sub_category = response.data;
        });
    },

    // Product publish/unpublish
    action(item) {
      const index = this.products.indexOf(item);
      axios
        .get(`product/publish_unpublish_product/${item.id}/`)
        .then((response) => {
          this.products[index].product_status = response.data.product_status;
          window.location.reload(true);
        });
    },

    // Specification publish/unpublish
    onSpecificationPublishUnpublish(item) {
      const index = this.SpecificationArray.indexOf(item);
      axios
        .get(`product/publish_unpublish_specification/${item.id}/`)
        .then((response) => {
          this.SpecificationArray[index].specification_status =
            response.data.specification_status;
        });
    },

    onSelectSubCategory() {
      axios
        .post(`category/subsubcategories/`, {
          name: this.addItem.selectedSubCatyegory,
        })
        .then((response) => {
          this.sub_sub_category = response.data;
        });
    },

    productselect() {
      if (this.select == "All Products") {
        axios.get("product/product_details/").then((response) => {
          this.products = response.data;
          this.loading = false;
        });
      } else if (this.select == "No Specification") {
        axios.get("product/nospecification_products/").then((response) => {
          this.products = response.data.data;
          this.loading = false;
        });
      } else if (this.select == "No Price") {
        axios.get("product/noprice_products/").then((response) => {
          this.products = response.data.data;
          this.loading = false;
        });
      } else {
        axios.get("product/product_details/").then((response) => {
          this.products = response.data;
          this.loading = false;
        });
      }
    },
    Specificationselect() {
      if (this.selectspec == "All Specifications") {
        axios.get("productdetails/getallSpecification/").then((response) => {
          this.SpecificationArray = response.data.data;
          this.loading = false;
        });
      } else if (this.selectspec == "Shared Specification") {
        axios
          .get("productdetails/all_own_shared_products/")
          .then((response) => {
            this.SpecificationArray = response.data.data;

            this.loading = false;
            // console.log("fjgbfg", this.SpecificationArray);
          });
      } else {
        axios.get("productdetails/getallSpecification/").then((response) => {
          this.SpecificationArray = response.data.data;
          this.loading = false;
        });
      }
    },
    ADDQuantity(item) {
      this.quantityID = item.id;
      this.quantityIndex = this.SpecificationArray.findIndex(
        (spec) => spec.id == item.id
      );
      Object.assign(this.specdataobj, item);
      // console.log(this.specdataobj);

      axios
        .get(`productdetails/quantity_info/${item.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.shop = response.data.data.shop;
            this.warehouse = response.data.data.warehouse;
            this.addquantitydialog = true;
          } else {
            this.color = "red";
            this.text = "Something went wrong !";
            //this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.color = "red";
          this.text = "Something went wrong !";
          //this.text = response.data.message;
          this.snackbar = true;
        });
    },

    // Add a new warhouse if user give quantity
    warehouseAdd(item) {
      //check array length for new item or not
      if (this.addedwarehousequantity.length > 0) {
        let index = this.addedwarehousequantity.findIndex(
          (warhouse) => warhouse.warehouse_id == item.warehouse_id
        );

        if (index != -1) {
          this.addedwarehousequantity[index].quantity = item.quantity;
        } else {
          this.addedwarehousequantity.push(item);
        }
      } else {
        this.addedwarehousequantity.push(item);
      }
    },

    // Add a new Shop if user give quantity
    shopAdd(item) {
      if (this.addedshopquantity.length > 0) {
        let index = this.addedshopquantity.findIndex(
          (shop) => shop.shop_id == item.shop_id
        );
        if (index != -1) {
          this.addedshopquantity[index].quantity = item.quantity;
        } else {
          this.addedshopquantity.push(item);
        }
      } else {
        this.addedshopquantity.push(item);
      }
    },

    confirmquantity(specdataobj) {
      let datas = {
        product_id: this.specdataobj.product_id,
        specification_id: this.quantityID,
        warehouse: this.addedwarehousequantity,
        shop: this.addedshopquantity,
        purchase_price: Number(this.specdataobj.product_price.purchase_price),
        selling_price: Number(this.specdataobj.product_price.price),
      };

      let total = 0;

      datas.warehouse.forEach((element) => {
        total = Number(total) + Number(element.quantity);
      });

      datas.shop.forEach((element) => {
        total = Number(total) + Number(element.quantity);
      });
      // console.log("this is datasss", datas);
      // If user did not add any quantity throw error
      if (!total) {
        this.text = "Quantity can not be empty";
        this.color = "warning";
        this.snackbar = true;
      } else {
        // If quantity and price exists
        axios
          .post(
            `productdetails/insert_specification_quantity/${this.user.user_id}/`,
            datas
          )
          .then((response) => {
            if (response.data.success) {
              this.text = response.data.message;
              this.color = "success";
              this.addquantitydialog = false;
              this.snackbar = true;
            } else {
              this.text = response.data.message;
              this.color = "warning";
              this.addquantitydialog = false;
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = "Something went wrong ";
            this.color = "warning";
            this.addquantitydialog = false;
            this.snackbar = true;
          });
      }
    },
    savetransit(selected) {
      // console.log("this is ", this.selected);
      this.Transfersavedialog = true;
    },
    CancelInvoice() {
      this.Transfersavedialog = false;
    },
    add_discount(item) {
      this.addDiscountDialog = true;
      Object.assign(this.specdataobjdiscount, item);
      // console.log(this.specdataobjdiscount);
    },
    Canceldiscount() {
      this.addDiscountDialog = false;
    },
    saveDist(specdataobjdiscount) {
      let datas = {
        discount_type: this.product_dist.discount_type,
        discount_amount: this.product_dist.discount_amount,
        discount_start_date: this.product_dist.discount_start_date,
        discount_end_date: this.product_dist.discount_end_date,
        product_id: this.specdataobjdiscount.product_id,
      };
      // console.log("this is item", datas);

      axios
        .post(`site/add_discount/${this.specdataobjdiscount.id}/`, datas)
        .then((response) => {
          if (response.data.success) {
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            this.addDiscountDialog = false;
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        });
    },
    async TransferSelectInfo() {
      // console.log(this.trnasferob);
      let perPage ;

      if (this.perPage1 === 'All'){
          perPage = this.totalItems1;

      }
      else
      {
          perPage = this.perPage1;
      }
      this.loading = true;
      await axios
        .get(
          `productdetails/get_product_list/${this.trnasferob.flagvalue}/${this.trnasferob.id}/?page=${this.currentPage1}&size=${perPage}&search_key=${this.search_key}`
        )
        .then((response) => {
            this.allchalanArray = response.data.results;
            console.log('results = ', response.data.results);
            this.totalItems1 = response.data.count;
            this.totalPages1 = parseInt(this.totalItems1 / this.perPage1)
            this.loading = false;
          
        });
    },

    SaveApproveInvoice(trnasferob) {
      this.isConfirmChalanButtonClick = false;
      let datas = {
        user_id: this.user.user_id,
        transaction_info: this.selected,
      };
      axios
        .post(`manager/create_chalan/${this.trnasferob.flagvalue}/`, datas)
        .then((response) => {
          if (response.data.success) {
            console.log('chalan success');
            // this.text = response.data.message;
            this.tempobj = response.data;
            // this.color = "success";
            // this.snackbar = true;
            this.Transfersavedialog = false;
            this.allchalanArray = this.allchalanArray.filter((element) => {
              this.selected.some((item) => item.id == element.id);
            });
            this.selected = [];
            this.TransferSelectInfo();
            this.viewchalandialog = true;
            this.isConfirmChalanButtonClick = true;
            
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        });
    },
    viewchalanAction(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/admin_chalan/${item.id}`,
      });
      routeData.href = `/dashboard/pages/admin_chalan/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },
    viewChalan(tempobj) {
      // console.log("this is clicked", tempobj);
      this.$router.push({
        path: "/dashboard/pages/admin_chalan/",
        query: { id: this.tempobj.data.id },
      });
    },

    myItems(items) {
      // console.log("After sorted", items);
      this.searchitemArray = items;
    },
    printSpec(searchitemArray) {
      let href = axios.defaults.baseURL + "site/allspec_pdf";
      window.open(href, "_blank");
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    this.initialize();
    this.getAllCastegory();
    this.productselect();
    this.Specificationselect();
    this.ADDQuantity();
    this.TransferSelectInfo();
  },
};
</script>


<style>
.cut {
  width: 500px;
  height: 560px;
  margin: 30px auto;
  margin-top: 0px;
}

.c-item {
  max-width: 800px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-view-box {
  outline: 1px solid red !important;
}

.thumbnailImage:hover {
  cursor: pointer;
}

.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

